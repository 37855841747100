



























































































































































































	import { Component, Vue } from 'vue-property-decorator';
	import Util from '@/utils/Util';
	import { internet } from '@/utils/Internet';
	// import { Icon } from '@/model/util/Icon';
	import AppContainerBase from '@/components/util/AppContainerBase.vue';
	import AppDialog from '@/components/util/AppDialog.vue';
	// import { internet } from '@/utils/Internet';
	// import { IDataTable } from '@/model/main/IDataTable';
	// import Swal from 'sweetalert2';
	@Component({
		name: 'MainScriptsTable',
		components: {
			AppContainerBase,
			AppDialog
		},
	})
	export default class MainScriptsTable extends Vue {
        public title = "Scripts cargados";
		public header_scripts = [
			{ text: 'ID Script', value: 'IDScript', align: 'start'},
			{ text: 'Nombre', value: 'nombre', align: 'start'},
			{ text: 'Categorias', value: 'categorias', align: 'start'},
			{ text: 'Subcategorias', value: 'subcategorias', align: 'start'},
			{ text: 'Acciones', value: 'acciones', align: 'start'},
		]
		public scripts:any = {};
		public row_scripts: Array<any> = [
			// {IDScript: '10', nombre: 'contacto directo test', categorias: '5', subcategorias: '15', acciones: ''},
			// {IDScript: '20', nombre: 'contacto indirecto test', categorias: '3', subcategorias: '8',  acciones: ''},
		]
		public general = true;
		public nombre_script = "";
		public header_edit_scripts = [
			{ text: 'Categoria', value: 'categoria', align: 'start'},
			{ text: 'Sub-categoria', value: 'subcategoria', align: 'start'},
			{ text: 'Tipo evaluacion', value: 'tipo_evaluacion', align: 'start'},
			{ text: 'Elementos explicitos', value: 'elemento_explicito', align: 'start'},
			// { text: 'Ponderacion', value: 'ponderadores', align: 'start'},
			{ text: 'Acciones', value: 'acciones', align: 'start'},
		]
		public row_edit_scripts: Array<any> = [
			// {id_sub: '01', categoria: 'Saludo', subcategoria: 'Saludo Inicial', tipo_evaluacion: 'Se encuentra al menos 1', elemento_explicito: ['Buen día', 'buenos dias', 'buena tarde', 'buenas tardes'], ponderadores: {defecto: '0.1'}, acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []},
			// {id_sub: '02', categoria: 'Saludo', subcategoria: 'Individualización cliente', tipo_evaluacion: 'Se encuentra al menos 1', elemento_explicito: ['Buen día', 'buenos dias', 'buena tarde', 'buenas tardes'], ponderadores: {defecto: '0.1'}, acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []},
			// {id_sub: '03', categoria: 'Saludo', subcategoria: 'Presentación Ejecutivo', tipo_evaluacion: 'Se encuentra al menos 1', elemento_explicito: ['Buen día', 'buenos dias', 'buena tarde', 'buenas tardes'], ponderadores: {defecto: '0.1'}, acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []},
			// {id_sub: '04', categoria: 'Información Deuda y negociación', subcategoria: 'Campañas asociadas', tipo_evaluacion: 'Se encuentra al menos 1', elemento_explicito: ['Buen día', 'buenos dias', 'buena tarde', 'buenas tardes'], ponderadores: {defecto: '0.1'}, acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []},
			// {id_sub: '05', categoria: 'Información Deuda y negociación', subcategoria: 'Motivo no Pago', tipo_evaluacion: 'Se encuentra al menos 1', elemento_explicito: ['Buen día', 'buenos dias', 'buena tarde', 'buenas tardes'], ponderadores: {defecto: '0.1'}, acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []},
			// {id_sub: '06', categoria: 'Despedida y Confirmación de Compromiso', subcategoria: 'Confirmación de pago', tipo_evaluacion: 'Se encuentra al menos 1', elemento_explicito: ['Buen día', 'buenos dias', 'buena tarde', 'buenas tardes'], ponderadores: {defecto: '0.1'}, acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []},
			// {id_sub: '07', categoria: 'Despedida y Confirmación de Compromiso', subcategoria: 'Tipificacion', tipo_evaluacion: 'Se encuentra al menos 1', elemento_explicito: ['Buen día', 'buenos dias', 'buena tarde', 'buenas tardes'], ponderadores: {defecto: '0.1'}, acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []},
		]
		public update_table = 100;
		public update_dialog = 200;
		public edit_dialog = false;
		public sub_edit:any = {id_sub: '', categoria: '', subcategoria: '', tipo_evaluacion: '', elemento_explicito: [], acciones: '', elemento_explicito_x2: {uno: [], dos: []}, elemento_explicito_campo: []};
		public nuevo_elem = "";
		public nuevo_elem_uno = "";
		public nuevo_elem_dos = "";

		mounted(): void {
            this.getScripts();
		}

		public getScripts() {
			const request_1 = internet
				.newRequest()
				.get(`scripts/getScripts`);
            
            Util.waitForPromises([request_1])
				.then((responses) => {
					const data = responses[0].data;
					this.scripts = data;
					this.row_scripts = [];
                    console.log(data);
					for (let script of Object.keys(data)) {
						let num_cat = 0;
						let num_sub = 0;
						let last_cat = '';
						for (let sub of data[script]) {
							num_sub += 1;
							if (sub['categoria'] !== last_cat) {
								last_cat = sub['categoria'];
								num_cat += 1;
							}
						}
						let nuevo_script = {
							IDScript: data[script][0]['IDScript'],
							nombre: data[script][0]['nombre'],
							categorias: num_cat.toString(),
							subcategorias: num_sub.toString(),
							acciones: ''
						}
						this.row_scripts.push(nuevo_script)
					}
				})
		}

		public edit(item:any) {	
			this.nombre_script = item.nombre;
			this.row_edit_scripts = [];
			for (let sub of this.scripts[this.nombre_script]) {
				console.log(sub);
				if (sub['elemento_explicito'].includes('#NO#')) {
					sub['tipo_evaluacion'] = 'No se encuentren';
					sub['elemento_explicito_x2'] = {uno: [], dos: []};
					sub['elemento_explicito_campo'] = [];
					sub['elemento_explicito'] = sub['elemento_explicito'].slice(4).split(' | ');
				} else if (sub['elemento_explicito'].includes('##x2##')) {
					let elementos = sub['elemento_explicito'].split('##x2##')
					sub['tipo_evaluacion'] = 'Se encuentran 2';
					sub['elemento_explicito_x2'] = {uno: elementos[0].split(' | '), dos: elementos[1].split(' | ')};
					sub['elemento_explicito_campo'] = [];
					sub['elemento_explicito'] = [];
				} else if (sub['elemento_explicito'].includes(' | ')) {
					sub['tipo_evaluacion'] = 'Se encuentra al menos 1';
					sub['elemento_explicito'] = sub['elemento_explicito'].split(' | ');
					sub['elemento_explicito_x2'] = {uno: [], dos: []};
					sub['elemento_explicito_campo'] = [];
				} else if (sub['elemento_explicito'].includes('#f#')) {
					sub['tipo_evaluacion'] = 'Cumpla campo';
					sub['elemento_explicito'] = [];
					sub['elemento_explicito_x2'] = {uno: [], dos: []};
					sub['elemento_explicito_campo'] = sub['elemento_explicito'].split('#f#');
				} else {
					sub['tipo_evaluacion'] = 'Se encuentra al menos 1';
					sub['elemento_explicito'] = [sub['elemento_explicito']];
					sub['elemento_explicito_x2'] = {uno: [], dos: []};
					sub['elemento_explicito_campo'] = [];
				}
				// sub['ponderadores'] = {defecto: '0.1'};
				sub['acciones'] = '';
				console.log(sub);
				this.row_edit_scripts.push(sub);
			}
			this.general = false;
		}

		public atras() {
			this.general = true;
		}

		public guardar() {
			console.log("Guardando");
			let save_script = [];
			for (let sub of this.row_edit_scripts) {
				let data = {
					nombre: sub['nombre'],
					IDScript: sub['IDScript'],
					id_cat: sub['id_cat'],
					id_sub: sub['id_sub'],
					cod: sub['cod'],
					categoria: sub['categoria'],
					subcategoria: sub['subcategoria'],
					elemento_explicito: '',
					ponderadores: sub['ponderadores']
				}
				if (sub['tipo_evaluacion'] == 'Se encuentran 2') {
					let uno = sub['elemento_explicito_x2']['uno'] as Array<string>;
					let dos = sub['elemento_explicito_x2']['dos'] as Array<string>;
					data['elemento_explicito'] = `${uno.join(' | ')}##x2##${dos.join(' | ')}`;
				} else if (sub['tipo_evaluacion'] == 'Cumpla campo') {
					let lista = sub['elemento_explicito_campo'] as Array<string>
					data['elemento_explicito'] = lista.join('#f#');
				} else if (sub['tipo_evaluacion'] == 'No se encuentren') {
					let lista = sub['elemento_explicito'] as Array<string>
					data['elemento_explicito'] = `#NO#${lista.join(' | ')}`;
				} else {
					let lista = sub['elemento_explicito'] as Array<string>
					data['elemento_explicito'] = lista.join(' | ');
				}
				save_script.push(data);
			}
			console.log(save_script);
			const request_1 = internet
				.newRequest()
				.post(`scripts/updateScript`, {data: save_script});
            Util.waitForPromises([request_1])
				.then((responses) => {
					console.log("Datos modificados");
					this.getScripts();
					this.general = true;
				})
				.catch(console.log)
				.finally(() => {
					//this.isLoading = false;
				});
		}

		public edit_sub(item:any) {
			console.log(item);
			this.sub_edit = item;
			this.edit_dialog = true;
		}

		public down_sub(item:any) {
			let indice = this.row_edit_scripts.findIndex(obj => obj === item);
			if (indice < this.row_edit_scripts.length - 1) {
				intercambiarElementos(this.row_edit_scripts, indice, indice + 1);
				this.update_table += 1;
				// console.log(this.row_edit_scripts);
			}
		}

		public up_sub(item:any) {
			let indice = this.row_edit_scripts.findIndex(obj => obj === item);
			if (indice >= 1) {
				intercambiarElementos(this.row_edit_scripts, indice, indice - 1);
				this.update_table += 1;
				// console.log(this.row_edit_scripts);
			}
		}

		public addElem(elem:string) {
			this.sub_edit.elemento_explicito.push(elem);
			this.nuevo_elem = "";
		}

		public deleteElem(elemento:string, index:any) {
			console.log(elemento);
			this.sub_edit.elemento_explicito.splice(index, 1);
		}

		public updateElem(elemento:string, index:any) {
			console.log(elemento);
			this.sub_edit.elemento_explicito.splice(index, 1);
			this.nuevo_elem = elemento;
		}

		public addElemx2(elem:string, lista:string) {
			if (lista === 'uno') {
				this.sub_edit.elemento_explicito_x2.uno.push(elem);
				this.nuevo_elem_uno = "";
			} else {
				this.sub_edit.elemento_explicito_x2.dos.push(elem);
				this.nuevo_elem_dos = "";
			}
		}

		public deleteElemx2(elemento:string, index:any, lista:string) {
			console.log(elemento);
			if (lista === 'uno') {
				this.sub_edit.elemento_explicito_x2.uno.splice(index, 1);
			} else {
				this.sub_edit.elemento_explicito_x2.dos.splice(index, 1);
			}
		}

		public updateElemx2(elemento:string, index:any, lista:string) {
			console.log(elemento);
			if (lista === 'uno') {
				this.sub_edit.elemento_explicito_x2.uno.splice(index, 1);
				this.nuevo_elem_uno = elemento;
			} else {
				this.sub_edit.elemento_explicito_x2.dos.splice(index, 1);
				this.nuevo_elem_dos = elemento;
			}
		}

		public actualizar() {
			// let indice = this.row_edit_scripts.findIndex(obj => obj.id_sub === this.sub_edit.id_sub);
			// this.row_edit_scripts[indice] = this.sub_edit;
			// this.sub_edit = {};
			this.update_table += 1;
			this.edit_dialog = false;
		}
    }

	function intercambiarElementos(lista:Array<any>, indice1:number, indice2:number) {
		// Verificar que los índices sean válidos
		if (indice1 >= 0 && indice1 < lista.length && indice2 >= 0 && indice2 < lista.length) {
			// Intercambiar los elementos
			let temp = lista[indice1];
			lista[indice1] = lista[indice2];
			lista[indice2] = temp;
		} else {
			console.error('Uno o ambos índices son inválidos');
		}
	}
