

























import {
	Component,
	Vue,
	Prop,
	Emit,
} from 'vue-property-decorator';

@Component({
	name: 'AppDialog',
})
export default class AppDialog extends Vue {
	@Prop({
		type: Boolean,
		required: true,
	})
	public isOpen!: boolean;
	public model = this.isOpen;
	@Prop({
		type: String,
		required: false,
		default: '',
	})
	public title!: string;
	@Prop({
		type: Boolean,
		required: false,
		default: true,
	})
	public subtitle!: string;
	@Prop({
		type: Boolean,
		required: false,
		default: true,
	})
	readonly persistent!: boolean;
	@Prop({
		type: Boolean,
		default: false,
	})
	readonly fullscreen!: boolean;
	@Prop({
		type: String,
		required: false,
	})
	readonly extra!: string;
	@Emit()
	public onClosed(): boolean {
		console.log('Evento Emitido');

		return false;
	}
}
